import React from "react"
import ShowsCard from '@components/showsCard';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';


class ShowsCardList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            refs: props.shows.map(node => React.createRef())
        }
    }

    playTrack = (ref, title) => {
        this.state.refs.forEach((audioRef, ind) => {
            let show = this.props.shows[ind];
            if (show.node.frontmatter.song.frontmatter.title !== title) {
                audioRef.current.audio.current.pause()
                audioRef.current.audio.current.currentTime = 0;
            }
        })
        trackCustomEvent({
            category: "Shows",
            action: "Play",
            label: title,
        })
    }

    render() {
        const allShows = this.props.shows.map((show, ind) => {
            const section = this.props.sections[`section${ind + 1}`];
            const isReversed = ind % 2;
            return <React.Fragment key={ind}><ShowsCard playTrack={this.playTrack} ref={this.state.refs[ind]} sectionId={section} data={show} isReversed={isReversed} /></React.Fragment>
        })

        return (
            <>
                {allShows}
            </>
        )
    }

}

export default ShowsCardList
