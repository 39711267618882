import React from "react"
import Img from "gatsby-image"
import styles from '@styles/components/showsCard.module.css';
import Audio from '@components/audio';


const ShowsCard = React.forwardRef(({ sectionId, data, isReversed, playTrack }, ref ) => {
  

    const { frontmatter, html } = data.node;
    const { title, image, song } = frontmatter;
    const songFile = song.frontmatter.file.publicURL;
    const songImage = song.frontmatter.image;
    const songTitle = song.frontmatter.title;
    const songShow = song.frontmatter.show;
    const songDesc = song.html;
    const handlePlayTrack = (ref, title) => {
        playTrack(ref, title)
    }
    
    const className = isReversed ? styles.reversedContainer : styles.container
    return (
        <div className={className} id={sectionId}>
            <div className={styles.info}>
                <h2>{title}</h2>
                <div dangerouslySetInnerHTML={{__html: html}}></div>
                <div className={styles.song}>
                    <Audio playTrack={handlePlayTrack} ref={ref} song={songFile} description={songDesc} image={songImage} title={songTitle} show={songShow}/>
                </div>
            </div>
            <div className={styles.img}>
                <Img fluid={image.childImageSharp.fluid} alt={`${title} poster`} />
            </div>
        </div>
    )
})

export default ShowsCard
