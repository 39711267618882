import React from "react"
import Img from "gatsby-image"
import { CSSTransition } from 'react-transition-group';
import styles from '@styles/components/showsHero.module.css';


const ShowsHero = ({ image1, image2, image3, sections }) => {
    const handleClick = (event) => {
        if (!event.target.alt) return;
        const section = event.target.alt.split(' ')[0];
        document.getElementById(section).scrollIntoView({behavior: 'smooth'});
    }
    const handleKeyDown = (event) => {
        if (event.key !== "Enter") return;
        const image = event.target.className.split("--")[1];
        const sectionName = 'section' + image.slice(-1);
        const section = sections[`${sectionName}`];
        document.getElementById(section).scrollIntoView({behavior: 'smooth'});
        document.getElementById(section).focus();
    }
    const { section1, section2, section3 } = sections;
    return (
        <div className={styles.container} onClick={handleClick} onKeyDown={handleKeyDown} role="presentation" aria-label="images with links to detailed sections on page">
            <CSSTransition appear={true} in={true} classNames="imageOne" timeout={1000}>
                <div className="imageOne" >
                    <div className={styles.image1} tabIndex="0" role="link" aria-label={`link to ${section1}`}>
                        <Img fluid={image1.childImageSharp.fluid} alt={section1 + ' vertical poster'} />
                    </div>
                </div>

            </CSSTransition>
            <CSSTransition appear={true} in={true} classNames="imageTwo" timeout={1000}>
                <div className="imageTwo">
                    <div className={styles.image2} tabIndex="0" role="link" aria-label={`link to ${section2}`}>
                        <Img fluid={image2.childImageSharp.fluid} alt={section2 + ' vertical poster'} />
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition appear={true} in={true} classNames="imageThree" timeout={1000}>
                <div className="imageThree">
                    <div className={styles.image3} tabIndex="0" role="link" aria-label={`link to ${section3}`}>
                        <Img fluid={image3.childImageSharp.fluid} alt={section3 + ' vertical poster'} />
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}

export default ShowsHero
