import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import ShowsHero from "@components/showsHero";
import ShowsCardList from '@components/showsCardList';
import styles from '@styles/pages/shows.module.css'

const ShowsPage = ({ data }) => {
  const { frontmatter } = data.showPage.edges[0].node;
  const { image1, image2, image3, section1, section2, section3 } = frontmatter;
  const sections = { section1, section2, section3 };
  const shows = data.showsInfo.edges;

  return (
    <Layout>
      <SEO title="Shows" />
      <ShowsHero image1={image1} image2={image2} image3={image3} sections={sections} />
      <div className={styles.hero}></div>

      <div className={styles.shows}>
        <ShowsCardList shows={shows} sections={sections} />
      </div>

    </Layout>
  );
}
export const query = graphql`
{
  showPage: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/shows\\\/index.md/"}}) {
      edges {
        node {
          html,
          frontmatter {
            image1 {
              childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            image2 {
              childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            image3 {
              childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            section1,
            section2, 
            section3
          }
        }
      }
    }

    showsInfo: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/shows\\\/(?!index.md)/"}}, sort: {fields: [frontmatter___order], order: ASC}) {
      edges {
        node {
          html,
          frontmatter {
            title
            song {
              html
              frontmatter {
                title
                show
                file {
                    publicURL
                }
                image {
                    childImageSharp {
                        fixed(width: 50) {
                          ...GatsbyImageSharpFixed
                        }
                    }
                }
              }

            }
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

          }
        }
      }
    }
}
`

export default ShowsPage
